<template>
  <main v-cloak id="content" role="main" class="overflow-hidden">
    <b-overlay :show="showDummy" :opacity="0.85" rounded="lg" no-wrap />
    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
      <div class="space-top-3 space-top-lg-4 space-bottom-2 space-bottom-lg-3 position-relative z-index-2">
        <div class="row no-gutters justify-content-lg-between">
          <div class="col-lg-4 d-none d-lg-inline-block">
            <figure class="position-absolute bottom-0 left-0 w-100" data-aos="fade-right">
              <img class="img-fluid" src="@/assets/svg/illustrations/career-hero-1.svg" alt="Image Description">
            </figure>
          </div>

          <div class="col-lg-4">
            <div class="text-center">
              <span class="d-block small font-weight-bold text-cap mb-2">WORK AT BENOVELTY</span>
              <h1 class="w-90 mx-auto">Be Novel Together With Our Fun Team</h1>
              <a
                id="ga-careers-see-position-button"
                class="mt-9 js-go-to btn btn-wide btn-primary transition-3d-hover"
                href="javascript:;"
                data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#openroles&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
              >See Open Positions
              </a>
            </div>
            <!-- Tablet & Mobile View Only -->
            <figure class="w-80 w-md-60 mx-auto d-lg-none d-block mt-7" data-aos="fade-up">
              <img class="img-fluid" src="@/assets/svg/illustrations/career-hero-2.svg" alt="Image Description">
            </figure>
          </div>
          <div class="col-lg-4 d-none d-lg-inline-block" data-aos="fade-left" data-aos-delay="200">
            <figure class="position-absolute bottom-0 left-0 w-100">
              <img class="img-fluid" src="@/assets/svg/illustrations/career-hero-2.svg" alt="Image Description">
            </figure>
          </div>
        </div>
      </div>
      <!-- SVG Shape -->
      <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
      <!-- End SVG Shape -->
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <span class="d-block small font-weight-bold text-cap mb-2">About Us</span>
        <h2>We Are a Group of Passionate Builders of Open API Ecosystem</h2>
      </div>
      <div class="row justify-content-lg-center">
        <div class="col-md-6 col-lg-6 mb-5 mb-md-0">
          <div class="pr-lg-6">
            <figure class="max-w-8rem w-100 mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-connect.svg">
            </figure>
            <h4>We connect businesses to opportunities</h4>
            <p>We build cloud-based Open API platforms to enable businesses from a wide range of sectors to rapidly deliver new service models and offerings at high efficiency and low cost.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 mb-0 mb-md-0">
          <div class="pl-lg-6">
            <figure class="max-w-8rem w-100 mb-3">
              <img class="img-fluid" src="@/assets/svg/icons/icon-customer.svg">
            </figure>
            <h4>We put customers at the heart of everything we do</h4>
            <p>Over 50 clients across industries, including financial services, healthcare, education, food &amp; beverage, etc., have worked with beNovelty to fast-track their digital transformation.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Values Section ========== -->
    <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-industry-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="w-md-80 text-center mx-md-auto mb-0 mb-md-3">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Values</span>
          <h2>How We Operate</h2>
        </div>
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-6">
            <div
              id="featuresSliderThumb"
              class="js-slick-carousel slick slick-pagination-grid slick-transform-off mx-auto py-4 py-md-3"
              data-hs-slick-carousel-options="{
                        &quot;infinite&quot;: true,
                        &quot;centerMode&quot;: true,
                        &quot;slidesToShow&quot;: 4,
                        &quot;isThumbs&quot;: true,
                        &quot;asNavFor&quot;: &quot;#featuresSliderNav&quot;
                        }"
            >
              <div class="w-25 w-lg-50">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block">beDedicated</h4>
                      <figure class="w-80 w-md-65 w-lg-50 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/illustrations/value-bededicated.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block">beKnowledgeable</h4>
                      <figure class="w-80 w-md-65 w-lg-50 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/illustrations/value-beknowledgeable.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h3 class="d-none d-lg-block">beTrusted</h3>
                      <figure class="w-80 w-md-65 w-lg-50 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/illustrations/value-betrusted.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h3 class="d-none d-lg-block">beInnovative</h3>
                      <figure class="w-80 w-md-65 w-lg-50 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/illustrations/value-beinnovative.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              id="featuresSliderNav"
              class="js-slick-carousel slick"
              data-hs-slick-carousel-options="{
                    &quot;infinite&quot;: true,
                    &quot;autoplay&quot;: true,
                    &quot;autoplaySpeed&quot;: 8000,
                    &quot;dots&quot;: true,
                    &quot;dotsClass&quot;: &quot;slick-pagination d-flex justify-content-center d-md-none mt-3&quot;,
                    &quot;fade&quot;: true,
                    &quot;asNavFor&quot;: &quot;#featuresSliderThumb&quot;,
                    &quot;prevArrow&quot;: &quot;<span class=\&quot;fas fa-arrow-left slick-arrow slick-arrow-white slick-arrow-left slick-arrow-centered-y rounded-circle ml-sm-n2 d-none d-md-block\&quot;></span>&quot;,
                    &quot;nextArrow&quot;: &quot;<span class=\&quot;fas fa-arrow-right slick-arrow slick-arrow-white slick-arrow-right slick-arrow-centered-y rounded-circle mr-sm-n2 d-none d-md-block\&quot;></span>&quot;
                    }"
            >

              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h3 class="text-center mb-3">beDedicated</h3>
                <figure class="w-40 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/illustrations/value-bededicated.svg">
                </figure>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Build strong, long-lasting relationships
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Strong ownership to product / project success
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    No matter where they work, have a role in project / product success. It requires the full spectrum of expertise
                  </div>
                </div>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h3 class="text-center mb-3">beKnowledgeable</h3>
                <figure class="w-40 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/illustrations/value-beknowledgeable.svg">
                </figure>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Continuous Learning / Improvement
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Knowledge Sharing and Knowledge base contribution
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Be a Sensei in specific areas
                  </div>
                </div>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h3 class="text-center mb-3">beTrusted</h3>
                <figure class="w-40 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/illustrations/value-betrusted.svg">
                </figure>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Build trust by listening, following through and keeping their word
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Rely on colleagues to do the right thing
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Preserve trust even when formal relationships end
                  </div>
                </div>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h3 class="text-center mb-3">beInnovative</h3>
                <figure class="w-40 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/illustrations/value-beinnovative.svg">
                </figure>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Be forward thinkers
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Work smart
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Embrace changes and challenges
                  </div>
                </div>
                <div class="media mb-3">
                  <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                    <i class="fas fa-check" />
                  </span>
                  <div class="media-body">
                    Take informed risks and champion new ideas
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Values Section ========== -->

    <!-- ========== Benefits Section ========== -->
    <div id="benefits" class="container space-2">
      <div class="text-center mb-5 mb-md-9">
        <div class="w-md-60 w-lg-100 mx-md-auto">
          <span class="d-block small font-weight-bold text-cap mb-2">Benefits and Perks</span>
          <h2>The Benefits of Being Small but Working at Scale</h2>
        </div>
        <div class="w-md-85 w-lg-80 mx-md-auto">
          <p>As a company founded in 2015, we are still striving for excellence in our operations. But when it comes to our benefits, we take good care of each of our employees and provide them with care, resources and support they need.</p>
        </div>
      </div>
      <div class="row">
        <div v-for="item in benefit_list" :key="item.title" class="col-md-6 col-lg-4 mb-3">
          <div class="card h-100">
            <div class="card-body">
              <div class="media">
                <figure class="w-100 max-w-6rem mr-3">
                  <img class="img-fluid" :src="require('@/assets/svg/icons/' + item.image_url)">
                </figure>
                <div class="media-body">
                  <h3>{{ item.title }}</h3>
                  <p class="text-body">{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Benefits Section ========== -->

    <!-- ========== Awards & Press Section ========== -->
    <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="w-md-80 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">An Award-Winning Workplace</span>
          <h2>beNovelty at a Glance</h2>
        </div>

        <div class="w-lg-90 mx-auto">
          <div class="row align-items-md-center mb-11">
            <div class="col-md-6 order-md-2 mb-3 mb-md-0">
              <div class="card">
                <div class="card-body mx-auto">
                  <img class="img-fluid" src="@/assets/img/images/erb-benovelty.jpg">
                </div>
              </div>
            </div>
            <div class="col-md-6 order-md-1">
              <div class="mb-3">
                <h3>ERB Manpower Developer Award</h3>
              </div>
              <p>beNovelty has been accredited as a "Manpower Developer (2020-2022)" by the Employees Retraining Board (ERB) in recognition of our outstanding achievements in manpower training and development.</p>
            </div>
          </div>

          <div class="row align-items-md-center mb-11">
            <div class="col-md-6 order-md-1 mb-3 mb-md-0">
              <div class="card">
                <div class="card-body w-65 w-md-60 mx-auto">
                  <img class="img-fluid" src="@/assets/svg/illustrations/careers-work-happily.svg">
                </div>
              </div>
            </div>
            <div class="col-md-6 order-md-2">
              <div class="mb-3">
                <h3>Work Happily to Innovate and Create</h3>
              </div>
              <p>Running a company is like playing in a band. In order to build a synergistic team, beNovelty encourages everyone in the company to bring new ideas and innovations, regardless of level and function.</p>
              <div class="mt-3">
                <a id="ga-careers-wantedly-button" class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#wantedlyPress">Learn More<i class="fas fa-angle-right fa-sm ml-2" /></a>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div id="youTubeVideoPlayer" class="video-player bg-dark mb-4">
                <img class="img-fluid video-player-preview" src="@/assets/img/images/hk10x-cover1.png">
                <a
                  class="js-inline-video-player video-player-btn video-player-centered"
                  href="javascript:;"
                  data-hs-video-player-options="{
                    &quot;videoId&quot;: &quot;UUOYcZD-Qr0&quot;,
                    &quot;parentSelector&quot;: &quot;#youTubeVideoPlayer&quot;,
                    &quot;targetSelector&quot;: &quot;#youTubeVideoIframe&quot;,
                    &quot;isAutoplay&quot;: true,
                    &quot;classMap&quot;: {
                    &quot;toggle&quot;: &quot;video-player-played&quot;
                    }
                  }"
                >
                  <span id="ga-careers-hk10x-video-play-button" class="video-player-icon">
                    <i class="fas fa-play" />
                  </span>
                </a>
                <div class="embed-responsive embed-responsive-16by9">
                  <div id="youTubeVideoIframe" />
                </div>
              </div>
              <div class="mb-3">
                <h3>HK10X—The Guiding Pioneering Spirit</h3>
              </div>
              <p>Human Touch is one of the most important corporate culture of beNovelty. With various mentoring relationships in the team, technology know-how and experience are being transferred more efficiently, and at the same time everyone learns from each other and grow together.</p>
              <div class="mt-3">
                <a id="ga-careers-hk10x-button" class="font-weight-bold" href="javascript:;" data-toggle="modal" data-target="#HK01Press">Learn More<i class="fas fa-angle-right fa-sm ml-2" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Awards & Press Section ========== -->

    <!-- ========== Teams Section ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span class="d-block small font-weight-bold text-cap mb-2">teams</span>
        <h2>Meet Our Teams</h2>
      </div>
      <div class="row" data-aos="fade-up">
        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#engineering&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Engineering &amp; Architecture</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>Turning the impossible into possible.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-engineering.svg">
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#business&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Business &amp; Sales</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>Discovering golden opportunity for our lovely clients.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-business.svg">
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#product&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Product &amp; Growth</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>Generating amazing product ideas for our lovely clients.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-product.svg">
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#ux&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Creative &amp; User Experience</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>Making the perfect design even perfect-er.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-ux.svg">
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#delivery&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Delivery Excellence &amp; Customer Experience</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>Making sure our lovely clients succeed.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-delivery.svg">
              </div>
            </div>
          </a>
        </div>

        <div class="col-md-6 col-lg-4 mb-5">
          <a
            class="js-go-to card card-bg-light h-100 shadow-none overflow-hidden transition-3d-hover"
            href="javascript:;"
            data-hs-go-to-options="{
                    &quot;targetSelector&quot;: &quot;#internship&quot;,
                    &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                    &quot;offsetTop&quot;: 0,
                    &quot;position&quot;: null,
                    &quot;animationIn&quot;: false,
                    &quot;animationOut&quot;: false
                    }"
          >
            <div class="pt-5 pl-4">
              <h4>Student Internship</h4>
            </div>
            <div class="row align-items-center no-gutters">
              <div class="col-8 col-md-7">
                <div class="pb-3 pl-4">
                  <p>One of our shining stars to be born in the future.</p>
                  <span class="font-size-1 font-weight-bold">See Open Positions <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </div>
              <div class="col-4 col-md-5 px-0">
                <img class="img-fluid" src="@/assets/svg/illustrations/team-internship.svg">
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Teams Section ========== -->

    <!-- ========== Openings Section ========== -->
    <div id="openroles" refs="openroles" class="gradient-y-sm-primary">
      <div class="container space-2">
        <div class="w-md-80 w-lg-50 text-center mx-md-auto">
          <span class="d-block small font-weight-bold text-cap mb-2">OPEN POSITIONS</span>
          <h2>Explore Opportunities</h2>
        </div>

        <div v-for="list in position_list" :id="list.name" :key="list.category" class="w-lg-85 mx-lg-auto">
          <div class="pt-11 mb-3">
            <h3>{{ list.category }}</h3>
          </div>
          <!-- <a v-for="item in list.position" :key="item.title" class="card card-frame py-3 px-4 mb-2" :href="'/careers/' + item.url"> -->
          <router-link v-for="item in list.position" :key="item.title" class="card card-frame py-3 px-4 mb-2" :to="{ path: `/careers/${item.url}` }">
            <div class="row align-items-sm-center">
              <span class="col-sm-6 text-dark mb-1 mb-sm-0">
                {{ item.title }}
              </span>
              <span class="col-6 col-sm-3 text-body" />
              <span class="col-6 col-sm-3 text-right">
                Apply <i class="fas fa-angle-right fa-sm ml-1" />
              </span>
            </div>
          </router-link>
          <!-- </a> -->
        </div>
      </div>

      <!-- CTA Section -->
      <div class="container space-2 space-bottom-3">
        <ContactUsBar :detail="{ title: 'Can\'t find a suitable position?', content: 'We are always looking for talents from any backgrounds with passions!' }" />
      </div>
      <!-- End CTA Section -->
    </div>
    <!-- ========== End Openings Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <!-- Wantedly -->
    <div id="wantedlyPress" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="wantedlyPressTitle" aria-hidden="true">
      <div class="modal-xl modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="wantedlyPressTitle" class="modal-title">Wantedly—20200103</h5>
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <span>Original Source From <a href="https://hk.wantedly.com/companies/benovelty/post_articles/202030" target="_blank">Wantedly</a></span>
            </div>
            <img class="w-100 img-fluid" src="@/assets/img/images/wantedly-20200103.jpg">
          </div>
        </div>
      </div>
    </div>

    <!-- HK10X -->
    <div id="HK01Press" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="HK01PressTitle" aria-hidden="true">
      <div class="modal-xl modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="HK01PressTitle" class="modal-title">HK01—20201209</h5>
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <span>Original Source From <a href="https://www.hk01.com/%E7%A4%BE%E6%9C%83%E6%96%B0%E8%81%9E/559168/%E9%A6%99%E6%B8%AF%E7%A7%91%E5%AD%B8%E5%9C%92hk10x%E5%89%B5%E7%A7%91%E7%B2%BE%E7%A5%9E-%E5%82%B3%E6%89%BF%E7%9F%A5%E8%AD%98%E8%BF%8E%E6%8E%A5%E6%8C%91%E6%88%B0" target="_blank">HK01</a></span>
            </div>
            <img class="w-100 img-fluid" src="@/assets/img/images/hk01-20201209.jpg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import ContactUsBar from '@/components/contact-us-bar'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
import HSVideoPlayer from '../../assets/vendor/hs-video-player/src/js/hs-video-player'
import { mapGetters } from 'vuex'
import { BOverlay } from 'bootstrap-vue'
import debounce from 'lodash/debounce'
export default {
  name: 'Careers',
  components: {
    ContactUsBar,
    BOverlay
  },
  data() {
    return {
      benefit_list: [
        {
          title: 'Healthcare',
          image_url: 'icon-24.svg',
          content: 'We provide comprehensive medical plans to keep you healthy and happy.'
        },
        {
          title: 'Workspace Your Way',
          image_url: 'icon-67.svg',
          content: 'We offer hybrid (remote & onsite) and flexible work arrangements and financial support for your workspace.'
        },
        {
          title: 'Learning & Development',
          image_url: 'icon-26.svg',
          content: 'On the job training and coached by industry leaders.'
        },
        {
          title: 'Vacation',
          image_url: 'icon-30.svg',
          content: 'We offer flexible vacation arrangement and generous annual time off.'
        },
        {
          title: 'Team Events',
          image_url: 'icon-14.svg',
          content: 'We got a fun team that loves game nights and playing board games during lunch.'
        },
        {
          title: 'Free Snacks',
          image_url: 'icon-28.svg',
          content: 'The office is always filled with snacks and beverages to keep you going through the day.'
        }
      ],
      team_list: [
        {
          url: 'engineering',
          title: 'Engineering & Architecture',
          description: 'Turning the impossible into possible.',
          image_url: 'team-software.svg'
        },
        {
          url: 'business',
          title: 'Business & Sales',
          description: 'Discovering golden opportunity for our clients.',
          image_url: 'team-business.svg'
        },
        {
          url: 'product',
          title: 'Product & Growth',
          description: 'Managing amazing events for our API community.',
          image_url: 'team-community.svg'
        },
        {
          url: 'ux',
          title: 'Creative & User Experience',
          description: 'Making the perfect design even perfect-er.',
          image_url: 'team-ux.svg'
        },
        {
          url: 'delivery',
          title: 'Delivery Excellence & Customer Experience',
          description: 'Managing amazing events for our API community.',
          image_url: 'team-community.svg'
        },
        {
          url: 'internship',
          title: 'Student Internship',
          description: 'One of our shining stars to be born in the future.',
          image_url: 'team-intern.svg'
        }
      ],
      position_list: [],
      isLoaded: true,
      career_press_list: [
        {
          url: 'https://www.youtube.com/watch?t=143&v=zOdVbmbzrc8&feature=youtu.be',
          image_url: 'tvb-pearl-magazine-2020.jpg',
          title: 'TVB Pearl Magazine - Class of Covid',
          organization: 'TVB Pearl',
          date: 'Auguest 26, 2020'
        },
        {
          url: 'https://www.hk01.com/%E9%96%8B%E7%BD%90/461059/hello-%E6%9C%AA%E4%BE%86%E4%BD%A0%E5%A5%BD-%E7%A7%91%E5%AD%B8%E5%9C%92%E5%90%B8%E6%89%8D%E5%8A%A9%E5%89%B5%E7%A7%91-%E7%B6%B2%E4%B8%8A%E6%8B%9B%E8%81%98%E7%8D%B2%E4%BC%81%E6%A5%AD%E7%9B%9B%E8%AE%9A',
          image_url: 'hk01-career-expo-2020.jpg',
          title: '【Hello，未來你好！】科學園吸才助創科　網上招聘獲企業盛讚',
          organization: 'HK01',
          date: 'April 17, 2020'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'careers', 'showDummy'
    ])
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        if (this.$route.hash) {
          this.scrollToSection(this.$route.hash.replace('#', ''))
        }
      }
    }
    if (document.readyState === 'complete') {
      this.$nextTick(debounce(() => {
        if (this.$route.hash) {
          this.scrollToSection(this.$route.hash.replace('#', ''))
        }
      }, 250))
    }
  },
  created() {
    this.position_list = this.$store.getters.careers.position_list
  },
  methods: {
    scrollToList() {
      this.$store.state.careers.scrollToList = true
    },
    scrollToSection(elementID) {
      window.scrollBy({
        top: document.getElementById(elementID).offsetTop - document.querySelector('#header').offsetHeight, // could be negative value
        left: 0
      })
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Careers | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/careers' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Careers | beNovelty' },
        { property: 'og:description', content: 'Be Novelty together! Discover current job openings and join our fun team.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/careers.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/careers' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
